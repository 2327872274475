html, body, #root {
  font-family: sans-serif;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.Select-menu {
  max-height: 100px;
}

.slider-with-tab * {
  background: #6e94ff;
  height: 30px !important;
  position: absolute;
  margin-top: 260px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.active-list-item {
  background: #bcded8;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.5);
}

.list-item:hover {
  background: #bcded8;
}

form table {
  overflow: visible;
}

.assign-button-selected {
  color: white !important;
}

.assign-button-selected:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

#loginFormEmail:-webkit-autofill,
#loginFormPassword:-webkit-autofill {
  content: '\feff';
}

.DateRangePickerInput_arrow {
  position: relative;
}

.DateRangePickerInput {
  width: 305px;
}

.actionContainer {
  display: inline-flex;
}
