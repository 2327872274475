.superContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    -moz-justify-content: center;
    -moz-align-items: center;
    -ms-flex-justify-content: center;
    -ms-flex-align-items: center;
    -o-flex-justify-content: center;
    -o-flex-align-items: center;
}

.orderNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: solid;
    border-right-width: 1px;
    margin-right: 8px;
    font-size: 0.8rem;
    color: white;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    -moz-justify-content: center;
    -moz-align-items: center;
    -ms-flex-justify-content: center;
    -ms-flex-align-items: center;
    -o-flex-justify-content: center;
    -o-flex-align-items: center;
}