/* alternating column backgrounds */
/*.vis-time-axis .vis-grid.vis-odd {*/
/*    background: #f5f5f5;*/
/*}*/

/*.vis-time-axis .vis-grid.vis-january {*/
/*    background: lightblue;*/
/*}*/
#vis-timeline .vis-background > .vis-group:nth-of-type(even) {
    background-color: rgba(211, 211, 211, 0.2);
}

#vis-timeline .vis-labelset > .vis-label:nth-of-type(odd) {
    background-color: rgba(211, 211, 211, 0.2);
}

/* disable the pointer events for the start and end custom time markers, that should be editable, only display the start and end dates */
#vis-timeline .vis-custom-time.startTimeUniqueId,
#vis-timeline .vis-custom-time.endTimeUniqueId {
    pointer-events: none;
}

#vis-timeline .vis-item.invalidEndingPoint {
    color: #fff;
    background-color: #d12121;
    opacity: 0.6;
}

#vis-timeline .vis-item.vis-selected {
    color: #fff;
    background-color: #333;
    opacity: 0.6;
}

/* custom select color */
/*.vis-selected {*/
/*    background: rgba(45, 45, 45, 0.58) !important;*/
/*}*/

/*.vis-time-axis .vis-text.vis-saturday,*/
/*.vis-time-axis .vis-text.vis-sunday {*/
/*    color: white;*/
/*}*/


/*.vis-time-axis .vis-grid.vis-wednesday {*/
/*    background: gray;*/
/*}*/
/*.vis-time-axis .vis-text.vis-wednesday {*/
/*    color: white;*/
/*}*/

li.item {
    list-style: none;
    width: 150px;
    color: #1a1a1a;
    background-color: #d5ddf6;
    border: 1px solid #97b0f8;
    border-radius: 2px;
    margin-bottom: 5px;
    padding: 5px 12px;
}

li.item:before {
    content: '≣';
    font-family: Arial, sans-serif;
    display: inline-block;
    font-size: inherit;
    cursor: move;
}

li.object-item {
    list-style: none;
    width: 150px;
    color: #1a1a1a;
    background-color: #d5ddf6;
    border: 1px solid #97b0f8;
    border-radius: 2px;
    margin-bottom: 5px;
    padding: 5px 12px;
}

li.object-item:before {
    content: '≣';
    font-family: Arial, sans-serif;
    display: inline-block;
    font-size: inherit;
    cursor: move;
}

.items-panel {
    display: flex;
    justify-content: space-around;
}
