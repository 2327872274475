#timeline .vis-item.eventFilter {
  background-color: #5a6969;
  color: white;
  top: 0;
}

#timeline .vis-item.order {
  background-color: #007069;
  color: white;
}

#timeline .vis-item.knownUnscheduled {
  background-color: #e29500;
  color: white;
}

#timeline .vis-item.noTrackingBackground {
  background-color: #d12121;
  opacity: 0.6;
}

#timeline .vis-item.forcedWait {
  background-color: #029292;
  color: #fff;
}

#timeline .vis-item.vehicleAvailability {
  background-color: #007069;
  opacity: 0.4;
}

#timeline .vis-item.unknownUnscheduled {
  background-color: #d12121;
  color: white;
}

#timeline .vis-item.scheduled {
  background-color: #007000;
  color: white;
}

#timeline .vis-item.driving {
  background-color: #334f8a;
  color: white;
}

#timeline .vis-item.trip {
  background-color: mediumpurple;
  color: white;
}

#timeline .vis-custom-time.slider-with-tab * {
  position: absolute !important;
  top: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  translate: -50%;
}
